import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

import BlockButton from "../components/BlockButton";
import LogoSection from "../components/LogoSection";
import ResponsiveTitle from "../components/ResponsiveTitle";
import { registerUser } from "../state/user";
import styles from "./css/login.module.scss";
import { formatError } from "../utils/helpers";

const SignUp = () => {
  const isDesktop = useMediaQuery({ minWidth: 1224 });

  const { t } = useTranslation();

  const initialValues = {
    username: "",
    password: "",
  };

  const dispatch = useDispatch();

  return (
    <Row justify="center">
      {isDesktop && (
        <Col span={12}>
          <LogoSection />
        </Col>
      )}
      <Col span={isDesktop ? 12 : 24}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <ResponsiveTitle>{t("login.signUp")}</ResponsiveTitle>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              try {
                await dispatch(registerUser(values));
              } catch (err) {
                actions.setFieldError("username", formatError(err));
                console.log(err);
              }
            }}
            render={({ isSubmitting }) => (
              <Form className={styles.form}>
                <Field
                  type="username"
                  name="username"
                  placeholder="Email"
                  className={styles.input}
                />
                <ErrorMessage name="username" />
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  className={styles.input}
                />
                <BlockButton htmlType="submit" loading={isSubmitting}>
                  {t("login.signUp")}
                </BlockButton>
              </Form>
            )}
          />
          <Row justify="center">
            <Button type="link" onClick={() => navigate("/app/login")}>
              {t("login.logIn")}
            </Button>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default SignUp;
